import React from 'react';
import { Helmet } from "react-helmet";
import FactSection from './FactSection.js';

const CoveredConditions = () => {
return (
        <>
            <Helmet>
                <title>What UrgentCare.com Can Help You With Today?</title>
            </Helmet>
            <div className="section  hero-doc ">
                   
                    <img src="/img/doc-computer-wide.jpeg" alt="UrgentCare" className="hero-doc-image"/>
                    <img src="img/ico2/Medical_Care_Icon.svg" height="96px" width="auto" className="hero-icon" alt='Urgent Care Medical Care'/>
                    <div className="hero-text">
                    <h1>What Can UrgentCare.com Help You With Today?</h1>
                    <p>Let UrgentCare.com Connect You To A Board-Certified Provider, Now.</p>
                    </div>
            </div>
     
            <div className="container doc-outline">
            <div className="banner-title">
              

                <h2 className="purple-box">UrgentCare.com Covered Conditions</h2>    

                 <a href="https://urgentcare.carehub.wellsync.com/conditions" className="btn green px-5 py-3" target='_blank' rel="noopener noreferrer">Chat With A Provider Online</a>  
            </div>
            <br className="clear-both"/>
                <div className="container ">
                    <div className="row ">
                      <div className="col-12 text-center mt-3 mb-5">
                          <h2 className="purple-text">On-Demand Acute Care and General Health</h2>
                           <h5>Board-Certified Doctors • On-Demand 24/7 • Save Time with Async Communication</h5>
                         </div>

                <div className="col-xs-12 col-md-4 conditions-list">
                   <h4 className="purple-text">General Health</h4>                   
                    <ul>
                        <li>Acid Reflux</li>
                        <li>Cold Sores</li>
                        <li>Headache</li>
                        <li>Pink Eye</li>
                        <li>Smoking Cessation</li>
                        <li>Allergies</li>
                        <li>Excessive Sweating</li>
                        <li>Nausea/Vomiting</li>
                        <li>Sinus Headaches/Sinus Infection</li>
                        <li>Cold/Flu - Adult</li>
                    </ul>

                    <h4>Men's Health</h4>
                    <ul>
                        <li>Erectile Dysfunction</li>
                        <li>Men's Hair Loss</li>
                        <li>Excessive Sweating</li>
                        <li>Premature Ejaculation</li>
                        <li>Acne</li>
                        <li>Genital Herpes</li>
                    </ul>
                </div>
                
                <div className="col-xs-12 col-md-4 conditions-list">
                <h4>Women's Health</h4>
                <ul>
                    <li>Acne</li>
                    <li>Bacterial Vaginosis/Yeast Infections</li>
                    <li>Eyelash Growth</li>
                    <li>Women's Hair Loss</li>
                    <li>Anti-Aging</li>
                    <li>Birth Control</li>
                    <li>Urinary Tract Infections (UTI)</li>
                    <li>Genital Herpes</li>
                </ul>
                <h4>Sexual Health</h4>
                <ul>
                    <li>Birth Control</li>
                    <li>Bacterial Vaginosis/Yeast Infections</li>
                    <li>Premature Ejaculation</li>
                    <li>Urinary Tract Infections
                    (UTI)</li>
                    <li>STI Exposure Treatment
                    (Doxy PEP)</li>
                    <li>Erectile Dysfunction</li>
                    <li>Genital Herpes</li>
                </ul>
                
  
                </div>
                <div className="col-xs-12 col-md-4 conditions-list">
                   <h4>Medication Refill</h4>                   
                    <ul>
                        <li>Short-Term Medication Refill</li>
                        <li>High Cholesterol Refill</li>
                        <li>High Blood Pressure Refill</li>
                        <li>Diabetic Medication Refill</li>
                    </ul>
                
                    <h4>Skin &amp; Hair</h4>
                    <ul>
                        <li>Acne</li>
                        <li>Dandruff</li>
                        <li>Men's Hair Loss</li>
                        <li>Skin Rashes</li>
                        <li>Excessive Sweating</li>
                        <li>Scabies</li>
                        <li>Anti-Aging</li>
                        <li>Eyelash Growth</li>
                        <li>Rosacea</li>
                        <li>Women's Hair Loss</li>
                        <li>Shingles</li>
                        <li>Poison Ivy</li>
                    </ul>
                </div>
                
            </div>
        </div>
   
     
           
    </div>

<FactSection />
     <div className="container mb-10">
   <div className="row p-4">
   <h4 className="text-center">How to get medical help without leaving your home?</h4>
   <p>If you're needing to speak with a doctor and prefer to stay at home, we have you covered. Urgentcare.com offers fast, 24/7/365 doctor communication. If you're having severe symptoms or need fast relief, talk to a certified doctor who can send a prescription to the pharmacy of your choice. Your Urgentcare.com doctor can also help determine if something else is going on.</p>

   <div className="text-center display-block">
    <a href="https://urgentcare.carehub.wellsync.com/conditions" className="btn green " target='_blank' rel="noopener noreferrer">Book Your Telemed Visit Now</a>
  </div>
            
  </div>
</div>
        </>
    )
}

                
export default CoveredConditions;
import React from 'react';
class FooterBanner extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
            isVisible: true
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
      if (this.props.clickHandler) {
        this.props.clickHandler();
        event.preventDefault();
        event.stopPropagation();
      }
    }

    handleClose() {
        this.setState({ isVisible: false });
    }

    render() {
      if (!this.state.isVisible) {
        return null;
      }
      
      return (
            <div className="ctabanner footer-cta-banner" id="ctabanner"  aria-labelledby="footerbanner">
                <div className="info">
                    <div className="half">
                        <h4>Prefer To Handle It From Home?</h4>
                        <p>Connect with a telemed <br/>provider now for <strong>$35</strong></p>
                    </div>

                    <a href="https://urgentcare.carehub.wellsync.com/conditions" onClick={this.handleClick} className="btn green" target='_blank' rel="noopener noreferrer">Book Your Telemed Visit Now</a>
                                          
                </div>
                       
           <button type="button" className="close" data-dismiss="ctabanner" aria-label="Close" onClick={this.handleClose}>
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
        )
    }
}

export default FooterBanner;
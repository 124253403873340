import React from 'react'

function Partnership() {
    return (
        <div className="section partner-with-us">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 offset-md-1 relative">
                        <img src="img/phone-doc.png" alt="urgent-care" className="img-fluid phone-doc" />
                    </div>
                    <div className="col-md-5 order-md-first text-center">
                        <h2 className="mb-3">There Are Now More Ways To Access Care At UrgentCare.com</h2>
                        <p>Need to speak with a doctor at home? We can help with that. Schedule a telemed visit today for $35</p>
                        <a href='https://urgentcare.carehub.wellsync.com/conditions' className="btn green my-3" target='_blank' rel="noopener noreferrer">
                            Book Your Telemed Visit Now
                        </a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partnership

import React from 'react';

const Footer2 = () => {
    return(
        <div className="footer-2">
            <div className="container">
                <div className="row">

                    <div className="col-md-6 text-center mb-4 mb-md-0">
                        <span><img src="/img/ico2/GLP-1c.png" width="auto" height="30px" className="text-white mr-1" alt=''/> Diabetic GLP-1 Program</span>
                        {/*<a href="http://www.AntiBodytesting.com" target="_blank" className="ml-2">AntiBodytesting.com</a>*/}
                        <a href='e.preventDefault()' className="ml-2">T2D.com</a>
                    </div>

                    <div className="col-md-6 text-center">
                        <span><img src="/img/ico/pharmacy.svg" width="auto" height="30px" className=" mr-1" alt=''/> Pharmacy Near Me</span>
                        {/*<a href="https://www.PharmacyNearMe.com" target="_blank" className="ml-2">PharmacyNearMe.com</a>*/}
                        <a  href='e.preventDefault()'className="ml-2">PharmacyNearMe.com</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer2;
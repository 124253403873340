import React from 'react'

function MedicalCareSection() {
    return (
        <div className="section" style= {{borderBottom: "1px solid rgba(195,202,206,2)"}}>  
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <img src="img/ico2/Medical_Care_Icon.svg" height="96px" width="auto" className="mb-4" alt='Urgent Care Medical Care'/>
                        <h2 className="text-center mb-4">Urgent Care <br/>Services Available</h2>
                        <p>An Urgent Care Center is a convenient place to address a lot of different types of issues, ailments and conditions. Here are just a few of the many ways an Urgent Care Center can help you and your loved ones:</p>
                    </div>
                </div>
               
            <div className="row comp justify-content-center  my-0 my-md-5 px-0">
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/covid-19-coronavirus/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/GLP-1.svg" height="55" width="auto" alt='GLP-1'/>
                        <title className="mt-4">GLP-1</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0" >
                        <a href="https://blog.urgentcare.com/symptoms-of-influenza-the-flu/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Cold_Flu.svg" height="55" width="auto"  alt=' Cold & Flu'/>
                        <title className="mt-4">Cold & Flu</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/symptoms-of-strep-throat/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Strep_Throat.svg" height="55" width="auto"  alt=' Strep Throat'/>
                        <title className="mt-4">Strep Throat</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/symptoms-of-allergies/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Allergies.svg" height="55" width="auto"  alt=' Allergies'/>
                        <title className="mt-4">Allergies</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/symptoms-of-asthma/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Asthma.svg" height="55" width="auto"  alt=' Asthma'/>
                        <title className="mt-4">Asthma</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/conjunctivitis-pink-eye/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Pink_Eye.svg" height="auto" width="75px"  alt=' Pink Eye'/>
                        <title className="mt-4">Pink Eye</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/otitis-ear-infection/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Ear_Infection.svg" height="55" width="auto"  alt=' Ear Infection'/>
                        <title className="mt-4">Ear Infection</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/urinary-tract-infection-uti/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/UTI.svg" height="55" width="auto"  alt=' Urinary Tract Infection'/>
                        <title className="mt-4">Urinary Tract Infection</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/sexually-transmitted-infection-or-disease-sti-std/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/STDs.svg" height="55" width="auto"  alt=' Sexually Transmitted'/>
                        <title className="mt-4">Sexually Transmitted</title></div></a>
                    </div>
                    <div className="col-6 col-md-2  ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/vomiting-and-diarrhea/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Vomiting_Diarrhea.svg" height="55" width="auto"  alt=' Vomiting & Diarrhea'/>
                        <title className="mt-4">Vomiting & Diarrhea</title></div></a>
                    </div>
                    <div className="col-6 col-md-2  ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/sprains-and-strains/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Sprains_Strains_Breaks.svg" height="55" width="auto"  alt=' Sprains, Strains & Breaks'/>
                        <title className="mt-4">Sprains, Strains & Breaks</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/laceration-cut-of-the-skin-and-soft-tissue/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico2/Lacerations_Stitches.svg" height="55" width="auto"  alt=' Laceration & Stitches'/>
                        <title className="mt-4">Laceration & Stitches</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <img src="img/ico2/Drug_Screening.svg" height="55" width="auto"  alt=' Drug Screening'/>
                        <title className="mt-4">Drug Screening</title>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <img src="img/ico2/Employment_Forms.svg" height="55" width="auto"  alt=' Employment Forms'/>
                        <title className="mt-4">Employment Forms</title>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <img src="img/ico2/School_Forms.svg" height="55" width="auto"  alt=' School Forms'/>
                        <title className="mt-4">School Forms</title>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default MedicalCareSection

import React from 'react';

class InternalDisplay extends React.Component {

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    console.log("handleClose");
    this.props.dismissHandler();
  }

    render() {
        return (
            <>      
      <div className="modal show modal-internal" id="internal-display"  tabndex="-1">
                     <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content"  id="myModalLabel">
                         
                      <button onClick={this.handleClose} type="button" className="close" style={{zIndex: 1000}}>
                           <span aria-hidden="true">&times;</span>
                       </button>
                          <div className="modal-body">
                            <p className="modal-text">Stop! Before moving forward please select the service you are looking for.</p>
                      
                            <div className="modal-footer">
                                <a href="https://urgentcare.carehub.wellsync.com/conditions" className="btn green">Online Telemed For $35</a>
                                <a href={this.props.inPersonUrl} className="btn orange">In-Person Visit</a>
                                <span className="text-cta">at Local Urgent Care</span>
                                
                            </div>
                          </div>
                      </div>
                          
                  </div>
              </div>
              </>
        )
    }
}

export default InternalDisplay;
import React from 'react';
import { Helmet } from "react-helmet";
import { CLAIM_REGEX } from "../../Helpers";
import AboutUsSection from './AboutUsSection';
import HeroSection from './HeroSection';
import LearnMoreSection from './LearnMoreSection';
import MedicalCareSection from './MedicalCareSection';
import Partnership from './Partnership';
import FooterBanner from '../FooterBanner';


class HomePage extends React.Component {

    componentDidMount() {
        console.log(this.props.location.search);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>UrgentCare.com: Find Your Local Urgent Care Center, Get Directions, Hours of Operation and COVID-19 Testing - Home</title>
                </Helmet>
                
                <HeroSection onZIPChange={this.props.onZIPChange} forClaiming={CLAIM_REGEX.test(this.props.location.search)} />
                {
                    !CLAIM_REGEX.test(this.props.location.search) &&
                    <>
                                 {/* <LetsGetCheckedSlider /> */}
                        {/* <Buyrapidtestsection /> */}
                        <LearnMoreSection />
                        <AboutUsSection />
                        {/*WIP feature*/}
                        {/*<BlogSection />*/}
                        <MedicalCareSection />
                        <Partnership />
                    </>
                }

              <FooterBanner />

            </>
        )
    }
}

export default HomePage;
import React from 'react';
import { getTimeByLocation } from "../../Client";
import {
    get12HourFormatTimeString, getRandomImageFilename,
    getTimeDecimalEquivalent, isOpenTodayV2
} from "../../Helpers";

const calculateDistanceInMiles = (fromLatitude, fromLongitude, toLatitude, toLongitude) => {
    const R = 3958.8; // Radius of the Earth in miles
    const dLat = toLatitude * Math.PI / 180 - fromLatitude * Math.PI / 180;
    const dLon = toLongitude * Math.PI / 180 - fromLongitude * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(fromLatitude * Math.PI / 180) * Math.cos(toLatitude * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (R * c);
}

class ResultBox extends React.Component {
    state = {
        distance: null,
        practiceUTCOffset: 0,
        userUTCOffset: 0,
        practiceDSTUTCOffset: 0,
        userDSTUTCOffset: 0,
        opening: '',
        closing: '',
    }

    componentDidMount() {
        // MOBILE TAG
        // const distance = getDistanceBetweenTwoLocations((isMobile) ? this.props.zipLocation :
        //                                                              this.props.userLocation, this.props.location);

        // const distance = getDistanceBetweenTwoLocationsV2((this.props.zipBased) ? this.props.zipLocation :
        //                                                                         this.props.userLocation, this.props.location);
        // distance.then(this.setDistance);
        
        const userLocation = (this.props.zipBased) ? this.props.zipLocation : this.props.userLocation;
        const practiceLocation = this.props.location;
        const localDistance = calculateDistanceInMiles(
            parseFloat(userLocation.lat), parseFloat(userLocation.lng),
            parseFloat(practiceLocation.lat), parseFloat(practiceLocation.lng),
        )
        this.setState({
            distance: localDistance.toFixed(1),
        });

        console.log('Local distance', localDistance);


        const practice_opening = this.setPracticeOpening();
        const practice_closing = this.setPracticeClosing();


        const deltaUTCOffset = ((((this.state.userUTCOffset + this.state.userDSTUTCOffset)
            - (this.state.practiceUTCOffset + this.state.practiceDSTUTCOffset))) / 60) / 60;
        const user_opening = practice_opening + deltaUTCOffset;
        const user_closing = practice_closing + deltaUTCOffset;

        this.setState({
            opening: get12HourFormatTimeString(user_opening),
            closing: get12HourFormatTimeString(user_closing),
        })
        
    }

    setPracticeOpening = () => {
        if (this.props.schedules.length === 0){
            return 0;
        }
        else {
            return getTimeDecimalEquivalent(this.props.schedules[0].opening);
        }
    }

    setPracticeClosing = () => {
        if (this.props.schedules.length === 0){
            return 0;
        }
        else {
            return getTimeDecimalEquivalent(this.props.schedules[0].closing);
        }
    }

    setOpening = (time) => {
        if (this.isHalfHour(time)) {
            this.setState({
                opening: Math.floor(time) + ':30 AM',
            })
            return;
        }
        else {
            this.setState({
                opening: time + ':00 AM'
            })
        }
    }

    setClosing = (time) => {
        if (time>12 && this.isHalfHour(time)) {
            this.setState({
                closing: Math.floor(time - 12) + ':30 PM',
            })
            return;
        }
        if (time>12 && !this.isHalfHour(time)) {
            this.setState({
                closing: (time - 12) + ':00 PM',
            })
            return;
        }
        if (time === 12 && this.isHalfHour(time)) {
            this.setState({
                closing: Math.floor(time) + ':30 PM'
            })
        }
        else {
            this.setState({
                closing: (time) + ':00 PM'
            })
        }
    }

    isHalfHour = (time) => {
        let half_hour = time - Math.floor(time);
        return half_hour !== 0;
    } 

    setDistance = (data) => {
        // MOBILE TAG
        // this.setState({
        //     distance: data.distance,
        // }, this.fetchUTCOffsets)
        console.log(data.mi);
        this.setState({
            distance: data.mi,
        });
    }

    computeUserOffsets = async () => {
        const today = Date.now() / 1000;
        const userUTCOffset = await getTimeByLocation(this.props.userLocation, today);
        console.log(userUTCOffset);

        if(userUTCOffset.error) {
            this.setState({
                redirect: true,
            });
            return;
        } else {
            this.setState({
                userUTCOffset: userUTCOffset.rawOffset,
                userDSTUTCOffset: userUTCOffset.dstOffset,
            }, ()=>console.log(this.state));
        }

    }

    computePracticeOffsets = async () => {
        // UTC offset
        const today = Date.now() / 1000;
        console.log('This is the current practice.');
        console.log(this.state.practice);
        const practiceUTCOffset = await getTimeByLocation(this.props.location, today);
        console.log(practiceUTCOffset);
        if(practiceUTCOffset.error) {
            this.setState({
                redirect: true
            });
            return;
        } else {
            this.setState({
                practiceUTCOffset: practiceUTCOffset.rawOffset,
                practiceDSTUTCOffset: practiceUTCOffset.dstOffset,
            }, () => console.log(this.state));
        }
    }



    render() {
        const status = isOpenTodayV2(this.props.schedules);

        // eslint-disable-next-line no-unused-vars
        const isPremier = this.props.premier ? "premier" : "";
        const isActive = this.props.practice.isActive ? "premier" : "";
        const isActiveButton = this.props.practice.isActive ? "isActiveButton" : "";
        let stars = [];
        for(let i = 0; i < 5; i++) {
            stars = i < this.props.ratings ? stars.concat(
                <i className="fas fa-star rated"></i>
            ) : stars.concat(
                <i className="fas fa-star"></i>
            );
        }

        const distance = this.state.distance != null ? (this.state.distance < 1 ? "<1 mi away" :
            this.state.distance + " mi away") : "";
        const imageFile = getRandomImageFilename();

        const clinicNameSlug = this.props.name.toLowerCase().replaceAll(' ', '-')
                                                            .replaceAll('.', '')
                                                            .replaceAll(',','')
                                                            .replaceAll("'",'');
        const cityNameSlug = this.props.city.toLowerCase().replaceAll(' ', '-')
                                                          .replaceAll('.', '')
                                                          .replaceAll(',','')
                                                          .replaceAll("'",'');
        const zipSlug = this.props.practice.zip.padStart(5, '0');
        const slug = `${this.props.id}-${clinicNameSlug}-${cityNameSlug}-${zipSlug}`;
        return (
            <>
                <div className="col-lg-6 col-12 h-100">
                    {/*<a href={`/practice/${this.props.id}`} target="_blank">*/}
                    
                        <div className={"result-box p-3 p-md-3 my-3 h-100 " + isActive} onClick={()=>{this.props.isActiveClickHandler(this.props.index, this.props.id)}}>
                            <div className="float-none float-md-right text-left d-block d-md-inline">
                                {/*<img src="/img/result-img-03.png" className="img-fluid rounded"/>*/}
                                <img src={imageFile} className="img-fluid rounded" alt=""/>
                            </div>
                            <h5 className="mb-3">{this.props.name}</h5>
                            <i className="fas fa-map-marker-alt mr-3"></i>{this.props.city} · {distance}
                            <div className="hours   mb-1">
                                <i className="fas fa-clock  mr-2"></i> {this.state.opening} - {this.state.closing}
                            </div>
                            <ul className={`mb-2 ${status.message !== "" ? "open" : "closed"}`}>
                                {/*// MOBILE TAG*/}
                                {/*<li>{status.status} · {(isMobile) ? "" : status.message}</li>*/}
                                <li>{status.status}</li>
                            </ul>
                            <a href={this.props.forClaiming?  `/practice/${slug}/?claim=True`: `/practice/${slug}`} className={"btn view-detail-button my-4 " + isActiveButton}>
                                View Details
                            </a>
                        </div>
                </div>
            </>
        )
    }
}

export default ResultBox;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import ScrollToTop from "./components/ScrollToTop";
import {createStore} from "redux";
import {Provider} from 'react-redux';

// Reducers
import initialState from "./state/initial_state";
import reducer from './state/reducers';

//*****************************************************************************************************************
//                                          REDUX STORE SETUP                                                    //
//*****************************************************************************************************************
const store = createStore(reducer, initialState);


//*****************************************************************************************************************
//                                               APP SETUP                                                       //
//*****************************************************************************************************************
ReactDOM.render(
    <Provider store={store}>
        <Router>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </Router>
    </Provider>,
    document.getElementById('root')
);



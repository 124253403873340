import React from 'react';
import {Map, GoogleApiWrapper, InfoWindow, Marker} from "google-maps-react";
import {getGoogleMapsAPIAccessToken} from "../../Client";
import {isMobile} from 'react-device-detect';

const mapStyles = {
    width: '100%',
    height: '100%'
};

class GoogleMapContainer extends React.Component {
    state = {
        center: {
            lat: 100,
            lng: 100,
        },
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        const bounds = new window.google.maps.LatLngBounds();
        let margins;

        if(this.props.locations && this.props.locations.length > 0) {
            if(this.props.hasActive !== null){
                let lat = parseFloat(this.props.locations[this.props.hasActive].latitude)
                
                let lng = parseFloat(this.props.locations[this.props.hasActive].longitude)
                let additionalCoordinates = {lat:lat-0.0003, lng: lng-0.0003}
                bounds.extend(new window.google.maps.LatLng(this.props.locations[this.props.hasActive].latitude,
                                                            this.props.locations[this.props.hasActive].longitude))
                bounds.extend(new window.google.maps.LatLng(additionalCoordinates.lat,additionalCoordinates.lng))
                    

                margins = {
                    top: 30,
                    bottom: 50,
                    right: 50,
                    left: 50,
                }
            } else {
                this.props.locations.forEach((practice) => {
                    bounds.extend(new window.google.maps.LatLng(practice.latitude,
                                                                practice.longitude));
                });
                margins = {
                    top: 60,
                    bottom: 100,
                    right: 100,
                    left: 100,
                }
            }
            

            // const practice = this.props.locations[this.props.locations.length - 1];
            // bounds.extend(new window.google.maps.LatLng(practice.practice.latitude,
            //     practice.practice.longitude));
        }

        if (!isMobile && this.props.showUserPin) bounds.extend(new window.google.maps.LatLng(this.props.lat, this.props.lng));

        // const boundaryCenter = bounds.getCenter();
        // this.refs.resultMap.map.setCenter(boundaryCenter);
        // this.refs.resultMap.map.panToBounds(bounds);

        // const size = {
        //     width: 640, // Map width in pixels
        //     height: 380, // Map height in pixels
        // };
        //
        // const newBounds = {
        //     ne: {
        //         lat: bounds.getNorthEast().lat(),
        //         lng: bounds.getNorthEast().lng(),
        //     },
        //     sw: {
        //         lat: bounds.getSouthWest().lat(),
        //         lng: bounds.getSouthWest().lng(),
        //     }
        // }
        // console.log(newBounds);
        // const ne = new window.google.maps.LatLng(newBounds.ne);
        // const sw = new window.google.maps.LatLng(newBounds.sw);
        // const finalBounds = new window.google.maps.LatLngBounds([ne, sw]);

        // map.fitBounds(bbox, {    padding: {top: 10, bottom:25, left: 15, right: 5}  });
        // console.log(margins)
        
        this.refs.resultMap.map.fitBounds(bounds, margins);

    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        })
        
        this.props.isActiveClickHandler(props.index, props.id)
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    onMapRightClick = () => {
        /* Dismisses the current active marker and returns the map to its initial zoom height */
        if (this.props?.onDismissActiveMarker) this.props.onDismissActiveMarker();

        this.setState({
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
        })
    }

    render() {
        const activeIcon = { url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png", scaledSize: { width: 45, height: 45 } };
        const markers = this.props.locations.map((practice, index) => {
            if (practice.isActive === true) {
                return (
                    <Marker
                        position={{ lat: practice.latitude, lng: practice.longitude }}
                        title={practice.name}
                        key={practice.id}
                        name={practice.name}
                        practice={practice}
                        onClick={this.onMarkerClick}
                        index={index}
                        id={practice.practice_id}
                        icon={activeIcon}
                    />
                )
            } else {
                return (
                    <Marker
                        position={{ lat: practice.latitude, lng: practice.longitude }}
                        title={practice.name}
                        key={practice.id}
                        name={practice.name}
                        practice={practice}
                        onClick={this.onMarkerClick}
                        index={index}
                        id={practice.practice_id}
                    />
                );

            }

        });
        // eslint-disable-next-line no-unused-vars
        let centerLocation = { lat: 15, lng: 121 };
        if(this.props.locations) {
            centerLocation = this.props.locations.length > 0 ? {
                lat: this.props.locations[Math.floor(this.props.locations.length / 2)].latitude,
                lng: this.props.locations[Math.floor(this.props.locations.length / 2)].longitude,
                } :
                {lat: this.props.lat, lng: this.props.lng};
        } else {
            centerLocation = {
                lat: this.props.lat,
                lng: this.props.lng,
            }
        }

        const initialCenter = {
            lat: this.props.lat,
            lng: this.props.lng,
        }
        let slug = "";
        if(this.state.selectedPlace.practice)
        {
            const clinicNameSlug = this.state.selectedPlace.practice.name.toLowerCase().replaceAll(' ', '-')
                .replaceAll('.', '')
                .replaceAll(',', '')
                .replaceAll("'", '');
            const cityNameSlug = this.state.selectedPlace.practice.city.toLowerCase().replaceAll(' ', '-')
                .replaceAll('.', '')
                .replaceAll(',', '')
                .replaceAll("'", '');
            const zipSlug = this.state.selectedPlace.practice.zip.padStart(5, '0');
            slug = `${this.state.selectedPlace.practice.practice_id}-${clinicNameSlug}-${cityNameSlug}-${zipSlug}`;
        }
        
        return(
            <Map
                google={this.props.google}
                ref="resultMap"
                style={mapStyles}
                center={ (!isMobile && this.props.showUserPin) ? initialCenter : undefined}
                // zoom={8}
                // onDblclick={()=>console.log("onDblclick")}
                onRightclick={() => this.onMapRightClick()}>
                {/*{*/}
                {/*    (!isMobile) &&*/}
                {/*    <Marker*/}
                {/*        position = {{lat: this.props.lat, lng: this.props.lng}}*/}
                {/*        title="You are here."*/}
                {/*        name="You are here."*/}
                {/*        onClick={this.onMarkerClick}*/}
                {/*        icon="/img/youarehere.png" />*/}
                {/*}*/}

                {markers}


                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}>
                    <div>
                        <a
                            href={`/practice/${slug}`}
                            className="map-popup"
                            target="_blank"
                            rel="noopener noreferrer">
                            {this.state.selectedPlace.name}
                        </a>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: getGoogleMapsAPIAccessToken(),
})(GoogleMapContainer);
import React from 'react';
import {DAYS,
        MONTHS,
        get12HourFormatTimeString} from "../../Helpers";

const DateTodaySection = () => {
    const dateToday = new Date();
    const dateString = `${DAYS[dateToday.getDay()]}, ${MONTHS[dateToday.getMonth()]} ` +
        `${dateToday.getDate()}, ${dateToday.getFullYear()}, ` +
        `${get12HourFormatTimeString(dateToday.getHours() + (dateToday.getMinutes()/60))}`;

    return (
        <div className="media xy-5 my-3">
            <img className="mr-3" src="/img/ico/calendar.svg" alt=" " />
                <div className="media-body">
                    Today is
                    <h5 className="mt-0">{dateString}</h5>
                </div>
        </div>
    )
}

export default DateTodaySection;
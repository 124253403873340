import React from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

const facts = [

  {
    sectionName: 'My Account',
    questions: [
      {
        question: "Is my information safe?",
        answer: "Your privacy and the safety of your data are extremely important to us. The platform has been crafted in accordance with all applicable privacy regulations. We have put in place robust security measures to safeguard your information. For further information, please review our ",
        linkText: "Privacy Policy",
        linkUrl: "https://wellsync.com/privacy-policy/",
        contact: "."
      },
      {
        question: "How do I reset my password?",
        answer: "To reset your password, ",
        linkText: "CLICK HERE",
        linkUrl: "https://patientportal.wellsync.com/forgot-password?redirect=%2F",
        contact: " If you still have trouble, please contact us at support@wellsync.com."
      },
      {
        question: "How do I update my payment information?",
        answer: "Payment information is updated each time you submit an assessment questionnaire during checkout."
      },
      {
        question: "Can I use Urgentcare.com Telemed in my state?",
        answer: "While the telemed services are available throughout most of the United States, certain states have laws that prevent clinicians from writing prescriptions without a live consultation and/or lab work. Start an assessment questionnaire and enter your state to see if your state is available for this service."
      },
      {
        question: "When will I be charged?",
        answer: "You will be charged for the assessment when you submit your questionnaire. Payment for prescriptions (if necessary) will be processed at your selected pharmacy."
      },
      {
        question: "Can I use insurance?",
        answer: "While we do not accept insurance for assessments, we pride ourselves on keeping the price less than the cost of most insurance co-pays. You can, however, use your insurance as you normally would to fill any necessary prescriptions."
      },
      {
        question: "How do I find my previous assessments?",
        answer: "Previous visits, profile information, and billing information can be found by logging in to your WellSync Patient Portal."
      },
    ]
  },
  {
    sectionName: 'Prescriptions and Refills',
    questions: [
      {
        question: "How do I know when my prescription is ready?",
        answer: "You will receive an email from WellSync indicating that there has been a change on your account. You may then login to the Patient Portal to view relevant messages from your medical provider as well as view any prescription information."
      },
      {
        question: "Can I complete an assessment questionnaire on behalf of someone else (such as a child or older parent)?",
        answer: "At this time, the assessment questionnaire can only be completed by the person seeking care."
      },
      {
        question: "What is the follow-up protocol if the provider has additional questions?",
        answer: "After reviewing your assessment questionnaire, your medical provider may reach out for additional information using the phone number you provided. This may be in the form of a phone call or a text message if you opted to receive texts."
      },
      {
        question: "Who are the providers writing these prescriptions?",
        answer: "Prescriptions are written by licensed and experienced healthcare providers, including physicians, nurse practitioners, and specialists, ensuring safe and personalized medication management for each patient."
      },
      {
        question: "How do I transfer my prescription to a different pharmacy after I have already selected one and checked out?",
        answer: "To change your pharmacy after you submit your assessment questionnaire, please contact the pharmacy the prescription was originally sent to and request that they transfer the prescription to your preferred pharmacy."
      },
      {
        question: "What if I have an urgent health issue?",
        answer: "For urgent or emergent issues, patients should call 911 or go to the nearest emergency room or urgent care facility. The platform is not monitored immediately for urgent health submissions."
      },
    ]
  },
];

const Fact = ({ fact }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="faq-list-item">
      <h6 className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <span className="hide show float-right inline-block">
          {isOpen ? (
            <ChevronUpIcon aria-hidden="true" className="icon-close" />
          ) : (
            <ChevronDownIcon aria-hidden="true" className="icon-close" />
          )}
        </span>
        {fact.question}
        
      </h6>
      {isOpen && <p className="faq-answer"> {fact.answer}
      {fact.linkUrl ? (
        <>
          {fact.linkUrl.startsWith('http') || fact.linkUrl.startsWith('//') ? (
            <a href={fact.linkUrl} target="_blank" rel="noopener noreferrer">
              {fact.linkText}
            </a>
          ) : (
            <a href={fact.linkUrl}>{fact.linkText}</a>
          )}
          {fact.contact}
        </>
      ) : (
        fact.contact
      )}</p>}
    </div>
  );
}

const FactSection = () => {
return (

        <div className="faq container">
        <h2 class="title">FAQ</h2>
          {facts.map((section) => (
            <div key={section.sectionName}>
              <h4>{section.sectionName}</h4>
              {section.questions.map((question) => (
                <Fact fact={question} key={question.question} />
              ))}
            </div>
          ))}
        </div>

    )
}

                
export default FactSection;